import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '../../Themes/themes';
import GlobalStyles from '../GlobalStyles/GlobalStyles';
import ScreeningContainerComponent from '../ScreeningContainerComponent/ScreeningContainerComponent';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import FooterComponent from '../FooterComponent/FooterComponent';
import configs from '../../configuration/configuration.json';
import { getClient } from '../../util/helper/Helper';
import { Helmet } from 'react-helmet';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.background};
  min-height: 100vh;
  &:focus{
    outline: none;
  }
  background-color: blue;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${props => props.theme.background};
  width: 100%;
`;

const HomeComponent = () =>{ 
  const globalState = useSelector((state) => state.global);
  const sections = useSelector((state) => state.sections);
  const dispatch = useDispatch();
  const selectedSection = useSelector((state) => state.selectedSection);
  const [showTransitionPage, setShowTransitionPage] = React.useState(null);
  const [hardSectionTimer, setHardSectionTimer] = React.useState(false);
  const hardTimedSections = globalState.config.hardTimedSections;

  if(!selectedSection && sections.length > 0){
    let sectionSet = false
    
    for(let i = 0; i < sections.length; i++) {

      let currentSection = sections[i];
      let previousSection = null;

      let hardTimer = false

      if(i > 0){
        previousSection = sections[i - 1];
      }

      if(previousSection !== null && hardSectionTimer){
        hardTimer = true;
      }

      if(currentSection.isTimed){
        if(currentSection.startTime === null){
          /*If the current section is timed and unstarted but the previous section is complete, 
            this is the current section*/
          if(previousSection && previousSection.endTime !== null){
            dispatch( {type: 'SET_SECTION', data: sections[i]} )
            sectionSet = true;
          /*If the current section is timed and unstarted but the previous section is timed and incomplete, 
            the previous section can no longer be accessed and this is the current timed section*/
          }else if(hardTimer === true && previousSection.isTimed && previousSection.startTime !== null){
            dispatch( {type: 'SET_SECTION', data: sections[i]} )
            sectionSet = true;
          }
        }
      }else if(currentSection.startTime !== null){
        //If the current section is untimed and started but not finished, it is the current section
        if(currentSection.endTime === null){
          dispatch( {type: 'SET_SECTION', data: sections[i]} )
          sectionSet = true;
        }
      }else{
        if(previousSection !== null){
          /*If this section is untimed and unstarted but the previous section is complete, 
          this is the current section*/
          if(previousSection.endTime !== null){
            dispatch( {type: 'SET_SECTION', data: sections[i]} )
            sectionSet = true;
          /*If this section is untimed and unstarted but the previous section is timed and started, 
          the previous section can no longer be accessed and this is the current timed section*/
          }else if(previousSection.isTimed && previousSection.startTime !== null){
            dispatch( {type: 'SET_SECTION', data: sections[i]} )
            sectionSet = true;
          }
        }
      } 
    }
    //If no section was set, the first section is the current section
    if(sectionSet === false){
      dispatch( {type: 'SET_SECTION', data: sections[0]} )
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  }

  if(selectedSection && selectedSection.rendering){
    scrollToTop();
    dispatch( {type: 'UPDATE_SECTION_RENDERING' });

    if(!selectedSection.showTransition){
      dispatch({type: 'INIT_SECTION', data: selectedSection});  
    }
  }

  const handleTransitionStart = (e) => {
    setShowTransitionPage(false);
    
    if(selectedSection.isTimed){
      dispatch({type: 'SET_TIMER', data: { started: true, timeRemaining: selectedSection.timeRemaining*1000, timeLeft: { minutes: selectedSection.timeRemaining / 60, seconds: 0 }, startTime: Date.now(), minitLimit: selectedSection.timeRemaining / 60 }})
    }
    dispatch({type: 'INIT_SECTION', data: selectedSection});
  }

  const handleTransitionBack =(e) =>{
    setShowTransitionPage(null);
    dispatch({type: 'INIT_SECTION', data: e});
  }

  const handleTransitionNext = (isFinalSection) => {
    if(!isFinalSection){
      setShowTransitionPage(null);
    }
  }

  const imgBasePath = process.env.REACT_APP_env_img_base_path;

  useEffect(() => {
    if(selectedSection){
      if(selectedSection.showTransition && showTransitionPage === null){
        setShowTransitionPage(selectedSection.showTransition);
      }
      if(!selectedSection.showTransition){
        setShowTransitionPage(null);
      }
      if(selectedSection.name && hardTimedSections.some(el => selectedSection.name.includes(el))){
        setHardSectionTimer(true)
      } else {
        setHardSectionTimer(false)
      }
    }
  }, [selectedSection, showTransitionPage])

  if(globalState && globalState.completed){
      // go here if they just submitted for the first time
      if(globalState.submitted){
        return(
          <Redirect to='/thankyou' />
        )
      // go here if they're returning to a previously completed assessment
      }else{
        return(
            <Redirect to = '/completed' />
        )
      }
  }else{
    return (
      <ThemeProvider theme={ themes[globalState.theme]}>   
        <StyledRoot>
          <Helmet>
            <title>Catalyte screening</title>
          </Helmet>
            <GlobalStyles></GlobalStyles>
            <HeaderComponent bannerImageUrl={selectedSection ? selectedSection.bannerImageUrl : null} logoImageUrl={imgBasePath + globalState.config.logo} sections={sections} currentSection={selectedSection} showLoginState={false}/>    
            <StyledWrapper>
              <ScreeningContainerComponent selectedSection={selectedSection} sections={sections} hardTimedSections={hardTimedSections} global={globalState} showTransitionPage={showTransitionPage} hardSectionTimer={hardSectionTimer} handleTransitionStart={handleTransitionStart} handleTransitionNext={handleTransitionNext} handleTransitionBack={handleTransitionBack}/>
            </StyledWrapper>
            <FooterComponent /> 
        </StyledRoot>
       </ThemeProvider>    
    );
  }
}

export default HomeComponent;
