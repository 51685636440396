import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Auth } from 'aws-amplify';
import { ThemeProvider } from 'styled-components';
import themes from '../../Themes/themes';
import GlobalStyles from '../../components/GlobalStyles/GlobalStyles';
import RegistrationComponent from '../../components/RegistrationComponent/RegistrationComponent';
import MobileRegistrationComponent from '../../components/RegistrationComponent/MobileRegistrationComponent';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getClient, debouncedEmailCheck } from '../../util/helper/Helper';
import 'url-search-params-polyfill';
import { isIE, isOpera } from 'react-device-detect';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const RegistrationContainer = () =>{
    const flags = useFlags();
    const globalState = useSelector((state) => state.global);
    const regState = useSelector((state) => state.registration);
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const config = useSelector(state => state.global && state.global.config || {"name": "default"});
    const [backendUser, setBackendUser] = React.useState({});

    let formName = 'primary';

    if(window.location.pathname === '/createoppcolumbia'){
      formName = 'overview'
    }

    const [currentForm, setCurrentForm] = React.useState(formName);
    const [userPass, setUserPass] = React.useState("");
    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');
    const oppToken = process.env.REACT_APP_env_createopp_client_token;
    const validCognitoPassword = regState.validCognitoPassword;

    useEffect(() => {
      if(config.registration.singlePage == true){
        formName = 'single'
      }

      setCurrentForm(formName);
    }, [config.name])

    useEffect(() => {
      dispatch({type: 'SET_CONFIG', theme: getClient().toLowerCase()})
      dispatch({type: 'SET_THEME', theme: getClient().toLowerCase()})
    }, [globalState.flags])

    useEffect(() => {
      if(globalState.config.registration.regOverview === true){
        setCurrentForm('overview')
      }
    }, [globalState.config.registration.regOverview])

    useEffect(() => {
      Auth.currentAuthenticatedUser()
      .then(data => {
         dispatch({type: 'COGNITO_USER', data: data});
         let firstName = data.attributes.given_name || regState.firstName;
         let lastName = data.attributes.family_name || regState.lastName;
         let email = data.attributes.email;
         let phone = data.attributes?.phone_number ? data.attributes.phone_number.replace(/^\+1(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3') : regState.phone;
         let state = data.attributes?.address ? JSON.parse(data.attributes.address).region : regState.state;
         let city = data.attributes?.address ? JSON.parse(data.attributes.address).locality : regState.city;
         let address = data.attributes?.address ? JSON.parse(data.attributes.address).street_address : regState.address;
         let zipcode = data.attributes?.address ? JSON.parse(data.attributes.address).postal_code : regState.zipcode;
         dispatch({ 'type': 'UPDATE_REGISTRATION_STATE', data: {...regState, firstName, lastName, email, phone, state, city, address, zipcode } });
      })
      .catch(err => console.log(err));
    }, [])

    useEffect(() => {
      dispatch({type: 'SET_FLAGS', flags})
    }, [flags])

    useEffect(() => {
      let client = getClient();
      dispatch({ 'type': 'UPDATE_REGISTRATION_STATE', data: {...regState, client } });   
    }, [])

    useEffect(() => {
      if(getClient() === 'createoppcolumbia'){
        token = oppToken;
      }
        
      if(token){
        dispatch({ 'type': 'UPDATE_REGISTRATION_STATE', data: {...regState, token } });
      }
      
    }, [token]);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0
      });
    }

    useEffect(() => {
      scrollToTop();
    }, [currentForm])

    useEffect(() => {
      let ssoRegState = localStorage.getItem('ssoRegState');

      if(ssoRegState && globalState.cognitoUser && globalState.cognitoUser.attributes && globalState.cognitoUser.attributes.email){
        try{

          ssoRegState = JSON.parse(ssoRegState);
          ssoRegState.email = globalState.cognitoUser.attributes.email;

          if(ssoRegState.ts < Date.now() - 30*1000){
            localStorage.removeItem("ssoRegState");
            ssoRegState = null;
          }

        }catch(e){
          console.log('failed to parse ssoRegState', e);
        }
      }

      if(ssoRegState && ssoRegState.email && ssoRegState){
        localStorage.removeItem("ssoRegState");
        dispatch({'type': 'REGISTER_USER_BACKEND', data: { ...ssoRegState }});
      }

      dispatch({ type: 'SET_GO_REGISTER', data: false });
    },[globalState.cognitoUser])

    useEffect(() => {
      // if the password is valid (no error from Cognito), set the confirmation form
      if(regState.validCognitoPassword) {
        setCurrentForm("confirmation");
      } else {
        return;
      }
    }, [regState.validCognitoPassword])

    useEffect(() => {
      if(regState.confirmationFailed) {
        setCurrentForm("confirmation")
      }
    }, [regState.confirmationFailed])

    if(globalState.goHome){
      return (
        <Redirect to='/welcome'/>
      )
    }

    const onClickSubmit = function(data){
      dispatch({ 'type': 'UPDATE_REGISTRATION_STATE', data });
      switch(currentForm){
        case "overview":
          setCurrentForm("primary");
          break;
        case "primary":
          setCurrentForm("secondary");
          break;
        case "secondary":
        case "single":
            if(globalState && globalState.cognitoUser){
              dispatch({'type': 'REGISTER_USER_BACKEND', data: { ...regState, ...data }});
            }else{
              setUserPass(data.password);
              delete regState.password;     
              dispatch({'type': 'REGISTER_USER_COGNITO', data: { ...regState, ...data }});
            }
          break;
        case "confirmation":
          if(data.cc){
            const confData = Object.assign({}, regState);
            confData.cc = data.cc;
            confData.password = userPass;
            dispatch({type: 'USER_CONFIRMING_COGNITO', confData });
          }
          break;
        default:
          break;
      }
    }

    const onClickBack = function(data){
      dispatch({ 'type': 'UPDATE_REGISTRATION_STATE', data });
      switch(currentForm){
        case "primary":
        case "overview":
          break;
        case "secondary":
          setCurrentForm("primary");            
          break;
        case("confirmation"):
          setCurrentForm("password");
          break;

        default:
          break;
      }
    }

    let componentProps = {...regState};
    componentProps.onClickSubmit = onClickSubmit;
    componentProps.onClickBack = onClickBack;
    componentProps.globalState = globalState;
    componentProps.currentForm = currentForm;
    componentProps.isSmallScreen = isSmallScreen;
    componentProps.showLoadingSpinner = globalState.showLoadingSpinner;

    if (isIE || isOpera) {
      return (<Redirect to='/unsupportedbrowser'/>)
    }

    return (
    <ThemeProvider theme={ themes[globalState.theme] }>
        <GlobalStyles />
        <StyledRoot tabIndex={-1}>
            <Helmet>
              <title>Catalyte screening registration</title>
            </Helmet>
            {isSmallScreen ? <MobileRegistrationComponent {...componentProps} debouncedEmailCheck={debouncedEmailCheck}></MobileRegistrationComponent> : <RegistrationComponent {...componentProps} debouncedEmailCheck={debouncedEmailCheck}></RegistrationComponent>}
        </StyledRoot>
    </ThemeProvider>
    );
  }

export default RegistrationContainer;
